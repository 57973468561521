export default {
  MAInsightsFaviconLogo1024: require('../assets/images/MAInsightsFaviconLogo1024.png'),
  MAInsightsFaviconLogo10241: require('../assets/images/MAInsightsFaviconLogo1024(1).png'),
  MAInsightsFaviconLogo10242: require('../assets/images/MAInsightsFaviconLogo1024(2).png'),
  NordeaLogoBlackAndWhite: require('../assets/images/NordeaLogoBlackAndWhite.png'),
  BCG: require('../assets/images/BCG.png'),
  Bain: require('../assets/images/Bain.png'),
  HIG: require('../assets/images/HIG.png'),
  Block: require('../assets/images/Block.png'),
  OnlineMeeting: require('../assets/images/OnlineMeeting.png'),
  OnlineMeeting1: require('../assets/images/OnlineMeeting(1).png'),
  Approved: require('../assets/images/Approved.png'),
  Flexibility: require('../assets/images/Flexibility.png'),
  PrivateEquity: require('../assets/images/PrivateEquity.png'),
  PartnershipHandshake: require('../assets/images/PartnershipHandshake.png'),
  Roadmap: require('../assets/images/Roadmap.png'),
  Roadmap1: require('../assets/images/Roadmap(1).png'),
  Roadmap2: require('../assets/images/Roadmap(2).png'),
  Funnel: require('../assets/images/Funnel.png'),
  arjunmuthukumar: require('../assets/images/ArjunMuthukumar.jpg'),
  sahanagiri: require('../assets/images/SahanaGiri.jpg'),
  mainsightsfaviconlogo10243: require('../assets/images/MAInsightsFaviconLogo1024(3).png'),
  mainsightsapp20theoneintelligencehubto: require('../assets/images/MAInsightsApp20TheOneIntelligenceHubTo….png'),
  img2634left: require('../assets/images/IMG2634Left.png'),
  img2634portrait: require('../assets/images/IMG2634Portrait.png'),
  img2635portrait: require('../assets/images/IMG2635Portrait.png'),
  cfs2landscape: require('../assets/images/CFS2Landscape.png'),
  img0037front: require('../assets/images/IMG0037Front.png'),
  AndreasTrappe6: require('../assets/images/AndreasTrappe6.jpg'),
  PhotoShootMarch2024Compressed: require('../assets/images/PhotoShootMarch2024Compressed.jpg'),
  EmilCompressed: require('../assets/images/EmilCompressed.jpg'),
  EmilCompressedSquare: require('../assets/images/EmilCompressedSquare.jpg'),
  MomoSquare: require('../assets/images/MomoSquare.jpg'),
  IdaSquare: require('../assets/images/IdaSquare.jpg'),
  EliasSquare: require('../assets/images/EliasSquare.jpg'),
  ASOSquare: require('../assets/images/ASOSquare.jpg'),
  Arjun: require('../assets/images/Arjun.png'),
  Janne: require('../assets/images/Janne.jpg'),
  Puzzle1: require('../assets/images/Puzzle(1).png'),
  DataResearch: require('../assets/images/DataResearch.png'),
  AxcelLogosvg: require('../assets/images/AxcelLogosvg.png'),
  EQT: require('../assets/images/EQT.png'),
  NC: require('../assets/images/NC.png'),
  FSN: require('../assets/images/FSN.png'),
  NorvestorEquity: require('../assets/images/NorvestorEquity.jpg'),
  Norvestor: require('../assets/images/Norvestor.png'),
  Polaris: require('../assets/images/Polaris.png'),
  Genui: require('../assets/images/Genui.png'),
  Genui2: require('../assets/images/Genui2.png'),
  Rothschild: require('../assets/images/Rothschild.png'),
  Barclays: require('../assets/images/Barclays.png'),
  Inflexion: require('../assets/images/Inflexion.png'),
  Intera: require('../assets/images/Intera.jpg'),
  Intera1: require('../assets/images/Intera(1).png'),
  EOS: require('../assets/images/EOS.png'),
  Moelis: require('../assets/images/Moelis.png'),
  Hg: require('../assets/images/Hg.png'),
  Freshfields: require('../assets/images/Freshfields.png'),
  Ardian: require('../assets/images/Ardian.png'),
  Permira: require('../assets/images/Permira.png'),
  BainCapital: require('../assets/images/BainCapital.png'),
  BNP: require('../assets/images/BNP.png'),
  Patricia: require('../assets/images/Patricia.png'),
  InvestorAB: require('../assets/images/InvestorAB.png'),
  Cinven: require('../assets/images/Cinven.png'),
  Riversidecompanylogotrademarked: require('../assets/images/Riversidecompanylogotrademarked.gif'),
  IdBNSCTxof1718269520716: require('../assets/images/IdBNSCTxof1718269520716.png'),
  Hayfin: require('../assets/images/Hayfin.png'),
  AdelisEquity: require('../assets/images/AdelisEquity.png'),
  Impilo: require('../assets/images/Impilo.png'),
  Latham: require('../assets/images/Latham.png'),
  Newsletter: require('../assets/images/Newsletter.png'),
  CloudData: require('../assets/images/CloudData.png'),
  SocialNetwork: require('../assets/images/SocialNetwork.png'),
  Networking: require('../assets/images/Networking.png'),
  Nodes: require('../assets/images/Nodes.png'),
  BuildingBlocks: require('../assets/images/BuildingBlocks.png'),
  BuildingBlocks1: require('../assets/images/BuildingBlocks(1).png'),
  Target: require('../assets/images/Target.png'),
  Networking1: require('../assets/images/Networking(1).png'),
  Knowledge: require('../assets/images/Knowledge.png'),
  NKPLogoLarge: require('../assets/images/NKPLogoLarge.png'),
  NKPLogoLarge2: require('../assets/images/NKPLogoLarge(2).png'),
  lucasnotgesportrait: require('../assets/images/LucasNötgesPortrait.jpg'),
  appstoreqrcode100: require('../assets/images/AppStoreQRCode100.png'),
  mainsights20migrationinformationvisual: require('../assets/images/MAInsights20MigrationInformationVisual.png'),
  img3067portrait: require('../assets/images/IMG3067Portrait.png'),
  img3068portrait: require('../assets/images/IMG3068Portrait.png'),
  img3072portrait: require('../assets/images/IMG3072Portrait.png'),
  img3073portrait: require('../assets/images/IMG3073Portrait.png'),
  img3074portrait: require('../assets/images/IMG3074Portrait.png'),
  img3074portraitportrait: require('../assets/images/IMG3074PortraitPortrait.png'),
  img3076portrait: require('../assets/images/IMG3076Portrait.png'),
};
