import React from 'react';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  LinearGradient,
  ScreenContainer,
  Touchable,
  VStack,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { Image, Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AboutScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [sliderValue, setSliderValue] = React.useState(0);
  const [textInput2Value, setTextInput2Value] = React.useState('');
  const [textInput3Value, setTextInput3Value] = React.useState('');
  const [textInput4Value, setTextInput4Value] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignContent: 'center',
          alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
          flexWrap: 'nowrap',
        },
        dimensions.width
      )}
    >
      {/* View 3 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: [
              { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              { minWidth: Breakpoints.Desktop, value: 'center' },
              { minWidth: Breakpoints.BigScreen, value: 'center' },
            ],
            alignItems: [
              { minWidth: Breakpoints.Laptop, value: 'stretch' },
              { minWidth: Breakpoints.Desktop, value: 'stretch' },
              { minWidth: Breakpoints.BigScreen, value: 'center' },
            ],
            alignSelf: [
              { minWidth: Breakpoints.Laptop, value: 'stretch' },
              { minWidth: Breakpoints.Desktop, value: 'stretch' },
              { minWidth: Breakpoints.BigScreen, value: 'stretch' },
            ],
            backgroundColor: theme.colors.background.brand,
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'flex-start',
            },
          },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: {
                minWidth: Breakpoints.Desktop,
                value: 'space-between',
              },
              alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
              alignSelf: [
                { minWidth: Breakpoints.Laptop, value: 'auto' },
                { minWidth: Breakpoints.Desktop, value: 'stretch' },
              ],
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'flex-start',
              },
              marginLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
              marginRight: { minWidth: Breakpoints.BigScreen, value: 250 },
            },
            dimensions.width
          )}
        >
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  alignContent: 'flex-end',
                  alignItems: {
                    minWidth: Breakpoints.Laptop,
                    value: 'flex-end',
                  },
                  alignSelf: 'auto',
                  justifyContent: [
                    { minWidth: Breakpoints.Mobile, value: 'space-between' },
                    { minWidth: Breakpoints.Laptop, value: 'space-between' },
                  ],
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: 10 },
                    { minWidth: Breakpoints.Desktop, value: 20 },
                  ],
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 40 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                }
              ),
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('HomeScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <HStack
                {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.HStackStyles(theme)['H Stack'].style,
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                        { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['mainsightsfaviconlogo10243'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 30, marginRight: 5, width: 30 }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Section heading'].style,
                      {
                        color: palettes.App.Orange,
                        fontFamily: 'Poppins_900Black',
                        lineHeight: 14,
                        paddingBottom: 0,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'M&A INSIGHTS'}
                </Text>
              </HStack>
            </Touchable>
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'hamburger_nav',
                        value: true,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'Ionicons/menu-sharp'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: null, color: theme.colors.text.strong }
                    ),
                    dimensions.width
                  )}
                  title={'\n'}
                />
              )}
            </>
            {/* H Stack 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.HStackStyles(theme)['H Stack'].style,
                    dimensions.width
                  )}
                >
                  {/* Button 3 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_laptop',
                          value: true,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.branding.primary,
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_400Regular',
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'LOG IN'}
                  />
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('AboutScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Laptop,
                            value: 'rgba(0, 0, 0, 0)',
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.branding.primary,
                          },
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 5,
                          },
                          borderWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 1,
                          },
                          color: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.branding.primary,
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'ABOUT'}
                  />
                  {/* Button 2 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('ContactScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.background.brand,
                        },
                        borderColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 5,
                        },
                        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Quicksand_500Medium',
                        },
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                      },
                      dimensions.width
                    )}
                    title={'CONTACT'}
                  />
                  {/* Button 4 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('ScheduleDemoScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.background.brand,
                        },
                        borderColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 5,
                        },
                        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Quicksand_500Medium',
                        },
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                      },
                      dimensions.width
                    )}
                    title={'DEMO'}
                  />
                  {/* Button 5 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('CompanyOwnersScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Laptop,
                            value: 'rgba(0, 0, 0, 0)',
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.branding.primary,
                          },
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 5,
                          },
                          borderWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 1,
                          },
                          color: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.branding.primary,
                          },
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'Quicksand_500Medium',
                            },
                            { minWidth: Breakpoints.Mobile, value: 'System' },
                          ],
                          fontWeight: {
                            minWidth: Breakpoints.Mobile,
                            value: '400',
                          },
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'FOR COMPANY OWNERS'}
                  />
                </HStack>
              )}
            </>
          </HStack>
        </View>
      </View>

      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={palettes.App.Purple}
        color2={theme.colors.branding.primary}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              paddingBottom: [
                { minWidth: Breakpoints.Laptop, value: 50 },
                { minWidth: Breakpoints.Tablet, value: 45 },
              ],
              paddingLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
            }
          ),
          dimensions.width
        )}
      >
        {/* Modal 2 */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              presentationStyle={'pageSheet'}
              transparent={true}
              visible={Constants['login_options_mobile']}
            >
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors.background.brand },
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        justifyContent: 'space-between',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-end',
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          Images['mainsightsfaviconlogo10243']
                        )}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 30, marginRight: 5, width: 30 }
                          ),
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: palettes.App.Orange,
                              fontFamily: 'Poppins_900Black',
                              lineHeight: 14,
                              paddingBottom: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'M&A INSIGHTS'}
                      </Text>
                    </HStack>
                  </Touchable>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_mobile',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'Ionicons/menu-sharp'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                        }
                      ),
                      dimensions.width
                    )}
                    title={''}
                  />
                </HStack>

                <VStack
                  {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        marginBottom: 25,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 30,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'We will be launching our SaaS platform 2.0 in August 2024, which will also be available as a native mobile app in the App Store and Play Store, respectively.\n\nIn the meantime, please continue using the existing web application which is fully supported until we migrate to the new platform.'
                    }
                  </Text>
                  {/* Button 4 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          navigation.navigate('HomeScreen');
                          setGlobalVariableValue({
                            key: 'hamburger_nav',
                            value: false,
                          });
                          await WebBrowser.openBrowserAsync(
                            'https://app.mainsights.io'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'MaterialCommunityIcons/web'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 5,
                          borderWidth: 1,
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 20,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Continue with web version'}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          marginBottom: [
                            { minWidth: Breakpoints.Mobile, value: 18.75 },
                            { minWidth: Breakpoints.Tablet, value: 30 },
                          ],
                          marginTop: 18.75,
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Available from 12 Aug, 2024:'}
                  </Text>
                  {/* Button 2 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    disabled={true}
                    icon={'Ionicons/logo-apple-appstore'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: palettes.App['Disabled Button'],
                          borderRadius: 5,
                          borderWidth: 1,
                          color: palettes.App['Disabled Button'],
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 10,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Download in App Store (iOS)'}
                  />
                  {/* Button 3 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'AntDesign/android1'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: palettes.App['Disabled Button'],
                          borderRadius: 5,
                          borderWidth: 1,
                          color: palettes.App['Disabled Button'],
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 20,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Download in Android Store'}
                  />
                </VStack>
              </View>
            </Modal>
          )}
        </>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'auto' },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: 20 },
                { minWidth: Breakpoints.BigScreen, value: 20 },
              ],
              maxWidth: [
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Hero heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 36 },
                    { minWidth: Breakpoints.Laptop, value: 24 },
                    { minWidth: Breakpoints.Desktop, value: 30 },
                  ],
                  marginBottom: 15,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: 45,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'M&A Insights was created to ensure that private market capital is deployed towards the best opportunities in an efficient manner.'
            }
          </Text>
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_600SemiBold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 20 },
                  ],
                  marginBottom: 25,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'Our mission is to create maximum visibility for private equity funds and advisory firms navigating the unlisted market, across sectors and size tiers, region by region.'
            }
          </Text>
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['DataResearch'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  marginLeft: [
                    { minWidth: Breakpoints.Tablet, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 20 },
                  ],
                  marginRight: 20,
                }
              ),
              dimensions.width
            )}
          />
        </View>

        <Modal
          animationType={'none'}
          supportedOrientations={['portrait', 'landscape']}
          presentationStyle={'pageSheet'}
          transparent={true}
          visible={Constants['hamburger_nav']}
        >
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors.background.brand },
              dimensions.width
            )}
          >
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  {
                    justifyContent: 'space-between',
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                  }
                ),
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                          { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['mainsightsfaviconlogo10243'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 30, marginRight: 5, width: 30 }
                      ),
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          color: palettes.App.Orange,
                          fontFamily: 'Poppins_900Black',
                          lineHeight: 14,
                          paddingBottom: 0,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'M&A INSIGHTS'}
                  </Text>
                </HStack>
              </Touchable>
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'Ionicons/menu-sharp'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                    }
                  ),
                  dimensions.width
                )}
                title={''}
              />
            </HStack>

            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  {
                    alignContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'flex-start',
                    },
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    marginBottom: 25,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 25,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Button 2 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Home'}
              />
              {/* Button 3 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('AboutScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_700Bold',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'About'}
              />
              {/* Button 3 2 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('CompanyOwnersScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'For company owners'}
              />
              {/* Button 4 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('ContactScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Contact Us'}
              />
              {/* Button 5 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('ScheduleDemoScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Schedule Demo'}
              />
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    setGlobalVariableValue({
                      key: 'login_options_mobile',
                      value: true,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: theme.colors.text.strong,
                      borderRadius: 5,
                      borderWidth: 1,
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_500Medium',
                      marginTop: 40,
                      maxWidth: 350,
                    }
                  ),
                  dimensions.width
                )}
                title={'Log In'}
              />
            </VStack>
          </View>
        </Modal>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'flex-end' },
              alignItems: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-end' },
              marginRight: { minWidth: Breakpoints.Laptop, value: 45 },
              maxWidth: { minWidth: Breakpoints.BigScreen, value: '50%' },
              minWidth: { minWidth: Breakpoints.BigScreen, value: '50%' },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'flex-start',
                },
                alignItems: [
                  { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                  { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                  { minWidth: Breakpoints.Tablet, value: 'flex-end' },
                ],
                alignSelf: {
                  minWidth: Breakpoints.Laptop,
                  value: 'flex-start',
                },
                maxWidth: { minWidth: Breakpoints.Laptop, value: '75%' },
              },
              dimensions.width
            )}
          />
        </View>
        {/* Modal 2 2 */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              presentationStyle={'pageSheet'}
              transparent={true}
              visible={Constants['login_options_laptop']}
            >
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors.background.brand },
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        justifyContent: 'space-between',
                        marginLeft: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 150 },
                        ],
                        marginRight: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 150 },
                        ],
                        marginTop: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-end',
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          Images['mainsightsfaviconlogo10243']
                        )}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 30, marginRight: 5, width: 30 }
                          ),
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: palettes.App.Orange,
                              fontFamily: 'Poppins_900Black',
                              lineHeight: 14,
                              paddingBottom: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'M&A INSIGHTS'}
                      </Text>
                    </HStack>
                  </Touchable>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_laptop',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'AntDesign/closecircleo'}
                    iconSize={25}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                        }
                      ),
                      dimensions.width
                    )}
                    title={''}
                  />
                </HStack>

                <VStack
                  {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      {
                        alignContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        alignItems: [
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                          { minWidth: Breakpoints.Tablet, value: 'center' },
                        ],
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        marginBottom: 25,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                        maxWidth: [
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: '75%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 26 },
                          marginBottom: {
                            minWidth: Breakpoints.Laptop,
                            value: 20,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Access M&A Insights anywhere and any time'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          fontSize: { minWidth: Breakpoints.Laptop, value: 18 },
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 30,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'We will be launching our SaaS platform 2.0 in August 2024, which will also be available as a native mobile app in the App Store and Play Store, respectively.\n\nIn the meantime, please continue using the existing web application which is fully supported until we migrate to the new platform.'
                    }
                  </Text>

                  <VStack
                    {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      dimensions.width
                    )}
                  >
                    {/* Button 4 */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            navigation.navigate('HomeScreen');
                            setGlobalVariableValue({
                              key: 'hamburger_nav',
                              value: false,
                            });
                            await WebBrowser.openBrowserAsync(
                              'https://app.mainsights.io'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderRadius: 5,
                            borderWidth: 1,
                            color: theme.colors.text.strong,
                            fontFamily: 'Quicksand_700Bold',
                            marginBottom: [
                              { minWidth: Breakpoints.Mobile, value: 10 },
                              { minWidth: Breakpoints.Laptop, value: 25 },
                            ],
                            marginTop: 20,
                            textAlign: 'left',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Ok, continue to web version'}
                    />
                    {/* Text 3 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Section heading']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Section heading']
                            .style,
                          {
                            fontFamily: 'Quicksand_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 18,
                            },
                            marginBottom: [
                              { minWidth: Breakpoints.Tablet, value: 30 },
                              { minWidth: Breakpoints.Laptop, value: 18.75 },
                            ],
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 18.75,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Available from 12 Aug, 2024:'}
                    </Text>

                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['Disabled Button']}
                        name={'AntDesign/qrcode'}
                        size={50}
                        style={StyleSheet.applyWidth(
                          {
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App['Disabled Button'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_400Regular',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 18,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'App Store (iOS)'}
                      </Text>
                    </HStack>
                    {/* H Stack 3 */}
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['Disabled Button']}
                        name={'AntDesign/qrcode'}
                        size={50}
                        style={StyleSheet.applyWidth(
                          {
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App['Disabled Button'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_400Regular',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 18,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Play Store (Android)'}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              </View>
            </Modal>
          )}
        </>
      </LinearGradient>
      {/* Linear Gradient 2 */}
      <LinearGradient
        endX={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.text.strong}
        color2={palettes.App.Purple}
        endY={100}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
              ],
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.BigScreen, value: 'center' },
              ],
              flexWrap: 'nowrap',
              justifyContent: 'center',
              paddingBottom: [
                { minWidth: Breakpoints.Tablet, value: 45 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              paddingLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
              paddingTop: 0,
            }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: {
                minWidth: Breakpoints.BigScreen,
                value: 'flex-end',
              },
              alignItems: {
                minWidth: Breakpoints.BigScreen,
                value: 'flex-start',
              },
              alignSelf: {
                minWidth: Breakpoints.BigScreen,
                value: 'flex-start',
              },
              marginLeft: { minWidth: Breakpoints.BigScreen, value: 500 },
              maxWidth: { minWidth: Breakpoints.BigScreen, value: '50%' },
            },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['Knowledge'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  height: 300,
                  marginTop: { minWidth: Breakpoints.BigScreen, value: 50 },
                  opacity: 0.2,
                  position: 'absolute',
                  width: 300,
                }
              ),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
              ],
              alignItems: 'flex-start',
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              justifyContent: 'center',
              marginBottom: 45,
              marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
              marginRight: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'What we do'}
          </Text>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_700Bold',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'We create visibility in selected M&A clusters'}
          </Text>
          {/* Text 4 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 25,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'Even when you have a strong network and an established reputation, keeping track of short- mid- and long-term opportunities and processes can be very difficult, and extremely time consuming.'
            }
          </Text>
          {/* Text 5 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 25,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'M&A Insights solves these problems by acquiring, structuring, and enriching information from thousands of sources - a high portion of our information set is gathered from non-public (proprietary) sources such as company owners and other stakeholders.'
            }
          </Text>
          {/* Text 6 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 25,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'Our research and datasets are delivered to  subscribers through daily newsfeeds, periodic reports, and our cloud-native SaaS platform.'
            }
          </Text>
        </View>
      </LinearGradient>
      {/* Linear Gradient 4 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        color1={theme.colors.text.strong}
        color2={theme.colors.branding.primary}
        style={StyleSheet.applyWidth(
          {
            alignContent: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
            alignItems: [
              { minWidth: Breakpoints.Tablet, value: 'stretch' },
              { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              { minWidth: Breakpoints.Desktop, value: 'flex-start' },
            ],
            paddingLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
          },
          dimensions.width
        )}
      >
        <HStack
          {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.HStackStyles(theme)['H Stack'].style,
              {
                alignSelf: { minWidth: Breakpoints.Tablet, value: 'auto' },
                flexWrap: [
                  { minWidth: Breakpoints.Mobile, value: 'wrap' },
                  { minWidth: Breakpoints.Laptop, value: 'nowrap' },
                ],
                gap: { minWidth: Breakpoints.Laptop, value: 40 },
                justifyContent: [
                  { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                  { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                  { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                  { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                ],
                marginBottom: 20,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 40 },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: 10 },
                  { minWidth: Breakpoints.Laptop, value: 20 },
                ],
                marginTop: 20,
                maxWidth: { minWidth: Breakpoints.Laptop, value: '75%' },
              }
            ),
            dimensions.width
          )}
        >
          <VStack
            {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.VStackStyles(theme)['V Stack'].style,
                {
                  alignItems: {
                    minWidth: Breakpoints.Tablet,
                    value: 'stretch',
                  },
                  marginBottom: 20,
                  marginRight: 10,
                  marginTop: 20,
                  width: [
                    { minWidth: Breakpoints.Mobile, value: 125 },
                    { minWidth: Breakpoints.Tablet, value: 120 },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_700Bold',
                    fontSize: 40,
                    textAlign: 'left',
                  }
                ),
                dimensions.width
              )}
            >
              {'2018'}
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  { color: theme.colors.background.brand, textAlign: 'left' }
                ),
                dimensions.width
              )}
            >
              {'Established'}
            </Text>
          </VStack>
          {/* V Stack 2 */}
          <VStack
            {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.VStackStyles(theme)['V Stack'].style,
                { marginBottom: 20, marginRight: 10, marginTop: 20, width: 125 }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_700Bold',
                    fontSize: 40,
                    textAlign: 'left',
                  }
                ),
                dimensions.width
              )}
            >
              {'>150'}
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  { color: theme.colors.background.brand, textAlign: 'left' }
                ),
                dimensions.width
              )}
            >
              {'Clients'}
            </Text>
          </VStack>
          {/* V Stack 3 */}
          <VStack
            {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.VStackStyles(theme)['V Stack'].style,
                { marginBottom: 20, marginRight: 10, marginTop: 20, width: 125 }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_700Bold',
                    fontSize: 40,
                    textAlign: 'left',
                  }
                ),
                dimensions.width
              )}
            >
              {'43%'}
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  { color: theme.colors.background.brand, textAlign: 'left' }
                ),
                dimensions.width
              )}
            >
              {'Annual ARR growth'}
            </Text>
          </VStack>
          {/* V Stack 4 */}
          <VStack
            {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.VStackStyles(theme)['V Stack'].style,
                { marginBottom: 20, marginRight: 10, marginTop: 20, width: 125 }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_700Bold',
                    fontSize: 40,
                    textAlign: 'left',
                  }
                ),
                dimensions.width
              )}
            >
              {'7'}
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  { color: theme.colors.background.brand, textAlign: 'left' }
                ),
                dimensions.width
              )}
            >
              {'Countries we cover'}
            </Text>
          </VStack>
        </HStack>

        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.Tablet, value: 'center' },
              ],
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              flexWrap: 'nowrap',
              marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  marginTop: 20,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'The first M&A Insights newsletter was released in 2018 to a handful of clients - since then much has happened and today it is no longer only a newsletter but a complete (and device-agnostic) M&A origination and analytics platform. \n-\nInitially we focused exclusively on the four Nordic countries, but in November 2023 - after several years of consolidating our market-leading position in the Nordics - we expanded to cover the DACH region also.\n-\nWe serve more than 150 private equity funds and M&A advisory firms, many of which are global corporations, and see virtually zero churn, which we like to think comes down to the quality, volume, and timing of the content we provide.'
            }
          </Text>
        </View>
      </LinearGradient>
      {/* Linear Gradient 3 */}
      <LinearGradient
        endX={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.text.strong}
        color2={theme.colors.branding.primary}
        endY={100}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              flexWrap: 'nowrap',
              paddingBottom: [
                { minWidth: Breakpoints.Mobile, value: 0 },
                { minWidth: Breakpoints.Tablet, value: 45 },
              ],
              paddingLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
              paddingTop: 0,
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'flex-start',
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              flexWrap: 'nowrap',
              marginBottom: 45,
              marginLeft: { minWidth: Breakpoints.Tablet, value: 20 },
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'auto' },
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Tablet, value: 0 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    {
                      minWidth: Breakpoints.Tablet,
                      value: dimensions.width >= Breakpoints.Tablet,
                    },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Leadership & Team'}
          </Text>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_700Bold',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Tablet, value: 0 },
                    {
                      minWidth: Breakpoints.Tablet,
                      value: dimensions.width >= Breakpoints.Tablet,
                    },
                  ],
                  marginRight: null,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Meet the team behind NKP | M&A Insights'}
          </Text>
          {/* Text 6 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  alignSelf: 'auto',
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 25,
                  marginLeft: [
                    { minWidth: Breakpoints.Tablet, value: 0 },
                    {
                      minWidth: Breakpoints.Tablet,
                      value: dimensions.width >= Breakpoints.Tablet,
                    },
                  ],
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'NKP, the company behind M&A Insights, was founded by Andreas von Buchwald - a former investment manager with private equity firm Axcel. Our data- and research model is tailored around the specific intelligence challenges facing the private market M&A ecosystem.'
            }
          </Text>

          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  flexWrap: 'wrap',
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Tablet, value: 0 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    {
                      minWidth: Breakpoints.Tablet,
                      value: dimensions.width >= Breakpoints.Tablet,
                    },
                  ],
                  marginRight: 10,
                }
              ),
              dimensions.width
            )}
          >
            {/* H Stack 2 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['AndreasTrappe6'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Andreas von Buchwald\nCEO & Founder'}
              </Text>
            </HStack>
            {/* H Stack 3 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['EmilCompressedSquare'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Emil Ohlsen\nCOO'}
              </Text>
            </HStack>
            {/* H Stack 4 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['MomoSquare'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Momo Berg\nAssociate'}
              </Text>
            </HStack>
            {/* H Stack 5 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['lucasnotgesportrait'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Lucas Nötges\nAssociate'}
              </Text>
            </HStack>
            {/* H Stack 6 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['EliasSquare'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Elias Lundberg\nAssociate'}
              </Text>
            </HStack>
            {/* H Stack 7 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['ASOSquare'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Anne-Sophie Schieren\nAssociate'}
              </Text>
            </HStack>
            {/* H Stack 8 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['arjunmuthukumar'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Arjun Muthukamar\nTech Lead'}
              </Text>
            </HStack>
            {/* H Stack 9 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['sahanagiri'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Sahana Giri\nUser Management'}
              </Text>
            </HStack>
            {/* H Stack 10 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { marginBottom: 25, marginTop: 25, width: 300 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['Janne'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Janne Andersen\nFinance'}
              </Text>
            </HStack>
          </HStack>
        </View>
        <Image
          resizeMode={'cover'}
          {...GlobalStyles.ImageStyles(theme)['Image'].props}
          source={imageSource(Images['PhotoShootMarch2024Compressed'])}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'].style, {
              borderRadius: 5,
              height: [
                { minWidth: Breakpoints.Mobile, value: 200 },
                { minWidth: Breakpoints.Tablet, value: 450 },
              ],
              marginBottom: 40,
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Tablet, value: 20 },
              ],
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
              width: [
                { minWidth: Breakpoints.Mobile, value: '90%' },
                { minWidth: Breakpoints.Tablet, value: '90%' },
              ],
            }),
            dimensions.width
          )}
        />
      </LinearGradient>
      {/* Linear Gradient 5 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].props}
        color1={theme.colors.text.strong}
        color2={theme.colors.branding.primary}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              gap: 0,
              paddingLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              marginLeft: 20,
              marginRight: 20,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Desktop, value: '75%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 10,
                  marginLeft: [
                    { minWidth: Breakpoints.Desktop, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
          >
            {'Data-driven and investor-centric'}
          </Text>
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Desktop, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
          >
            {'Laying the research puzzle, piece by piece'}
          </Text>
          {/* Text 3 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_400Regular',
                  fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                  marginLeft: [
                    { minWidth: Breakpoints.Desktop, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {
              'We all come from a financial background and employ a data-driven investor-centric approach with a focus on systematic proactive monitoring of would-be targets and opportunities - enabling us to provide clients with a holistic understanding of the "here and now" as well as the longer term pipeline in a highly structured format.'
            }
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                  { minWidth: Breakpoints.Tablet, value: 'flex-end' },
                ],
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginBottom: 20,
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['Puzzle1'])}
              style={StyleSheet.applyWidth(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                dimensions.width
              )}
            />
          </View>
        </View>
      </LinearGradient>
      {/* Linear Gradient 6 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].props}
        color1={palettes.App.Purple}
        color2={theme.colors.branding.primary}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].style,
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              flexWrap: 'nowrap',
              gap: 1,
              minHeight: [
                { minWidth: Breakpoints.Mobile, value: 600 },
                { minWidth: Breakpoints.Tablet, value: 350 },
                { minWidth: Breakpoints.Desktop, value: 400 },
                { minWidth: Breakpoints.Laptop, value: 450 },
              ],
              paddingLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Desktop, value: 'stretch' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              marginBottom: 20,
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Tablet, value: 20 },
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Desktop, value: 20 },
              ],
              marginRight: 20,
              marginTop: 20,
              maxWidth: [
                { minWidth: Breakpoints.Desktop, value: '75%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 10,
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 20 },
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
          >
            {'Some of our clients'}
          </Text>
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 14 },
                    { minWidth: Breakpoints.Desktop, value: 18 },
                  ],
                  marginBottom: 10,
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 20 },
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
          >
            {
              'Both global and local top players rely on M&A Insights to continuously be on top of market movements and upcoming opportunities in their core markets.'
            }
          </Text>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              alignItems: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              marginBottom: [
                { minWidth: Breakpoints.Tablet, value: 45 },
                { minWidth: Breakpoints.Mobile, value: 25 },
              ],
              marginLeft: [
                { minWidth: Breakpoints.Tablet, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Desktop, value: 20 },
              ],
              marginRight: { minWidth: Breakpoints.Tablet, value: 20 },
              maxWidth: [
                { minWidth: Breakpoints.Desktop, value: '75%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
              ],
            },
            dimensions.width
          )}
        >
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  flexWrap: 'wrap',
                  justifyContent: [
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                    { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                    { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                    { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                  ],
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 10 },
                }
              ),
              dimensions.width
            )}
          >
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['EQT'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 10 },
                      { minWidth: Breakpoints.Tablet, value: 0 },
                      {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.width >= Breakpoints.Tablet === true,
                      },
                    ],
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 2 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['NC'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 3 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['FSN'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 4 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Norvestor'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 5 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Polaris'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 6 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Genui2'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 7 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Rothschild'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 8 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Barclays'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 9 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Inflexion'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 10 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Intera1'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 11 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['EOS'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 10 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: dimensions.width >= Breakpoints.Laptop,
                      },
                    ],
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 12 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Moelis'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 10 },
                      {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.width >= Breakpoints.Tablet,
                      },
                    ],
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 16 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Ardian'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 13 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Hg'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 14 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'cover'}
              source={imageSource(Images['Freshfields'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 15 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['AxcelLogosvg'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 17 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Permira'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 18 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['BainCapital'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 19 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['BNP'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 20 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Cinven'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 21 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['IdBNSCTxof1718269520716'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 10 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: dimensions.width >= Breakpoints.Laptop,
                      },
                    ],
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 22 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Hayfin'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 23 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['AdelisEquity'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 10 },
                      { minWidth: Breakpoints.Tablet, value: 0 },
                      { minWidth: Breakpoints.Laptop, value: 10 },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: dimensions.width >= Breakpoints.Laptop,
                      },
                      {
                        minWidth: Breakpoints.Tablet,
                        value: dimensions.width >= Breakpoints.Tablet,
                      },
                    ],
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 24 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Impilo'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 25 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Latham'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 26 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['NordeaLogoBlackAndWhite'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 27 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['HIG'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
            {/* Image 28 */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'contain'}
              source={imageSource(Images['Bain'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: 50,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 50,
                  }
                ),
                dimensions.width
              )}
            />
          </HStack>
        </View>
      </LinearGradient>
      {/* Linear Gradient 2 2 */}
      <LinearGradient
        endX={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.text.strong}
        color2={palettes.App.Purple}
        endY={100}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              flexWrap: 'nowrap',
              paddingBottom: [
                { minWidth: Breakpoints.Tablet, value: 45 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              paddingLeft: { minWidth: Breakpoints.BigScreen, value: 75 },
              paddingTop: 0,
            }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Tablet, value: 20 },
                { minWidth: Breakpoints.Mobile, value: 20 },
              ],
              marginRight: 20,
              marginTop: [
                { minWidth: Breakpoints.Laptop, value: 45 },
                { minWidth: Breakpoints.Tablet, value: 25 },
                { minWidth: Breakpoints.Mobile, value: 25 },
              ],
              maxWidth: { minWidth: Breakpoints.Laptop, value: '75%' },
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  alignSelf: {
                    minWidth: Breakpoints.Laptop,
                    value: 'flex-start',
                  },
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginRight: 20,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Do you own or advise a company we should write about?'}
          </Text>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  alignSelf: {
                    minWidth: Breakpoints.Laptop,
                    value: 'flex-start',
                  },
                  fontFamily: {
                    minWidth: Breakpoints.Desktop,
                    value: 'Quicksand_400Regular',
                  },
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Laptop, value: 0 },
                    { minWidth: Breakpoints.Tablet, value: 0 },
                    { minWidth: Breakpoints.Mobile, value: 0 },
                    { minWidth: Breakpoints.Desktop, value: 0 },
                  ],
                  marginRight: 0,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'Having your company portrayed in M&A Insights is a highly effective way to drive-up interest among would-be investors, whether you are currently considering a sale or may not do so down the line.'
            }
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
              ],
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              justifyContent: 'center',
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Tablet, value: 20 },
              ],
              marginTop: [
                { minWidth: Breakpoints.Mobile, value: 45 },
                { minWidth: Breakpoints.Laptop, value: 20 },
              ],
              maxWidth: [
                { minWidth: Breakpoints.BigScreen, value: '60%' },
                { minWidth: Breakpoints.Laptop, value: '100%' },
                {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width >= Breakpoints.Laptop,
                },
              ],
            },
            dimensions.width
          )}
        >
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  flexWrap: 'wrap',
                  gap: { minWidth: Breakpoints.Laptop, value: 30 },
                  justifyContent: [
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                    { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                    { minWidth: Breakpoints.BigScreen, value: 'center' },
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                  ],
                  marginBottom: [
                    { minWidth: Breakpoints.Tablet, value: 45 },
                    { minWidth: Breakpoints.Mobile, value: 45 },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  { alignItems: 'center', marginBottom: 25, marginTop: 15 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['Flexibility'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_600SemiBold',
                      fontSize: { minWidth: Breakpoints.Desktop, value: 16 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Completely free and non-binding'}
              </Text>
            </VStack>
            {/* V Stack 2 */}
            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  { alignItems: 'center', marginBottom: 25, marginTop: 15 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['PrivateEquity'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_600SemiBold',
                      fontSize: { minWidth: Breakpoints.Desktop, value: 16 },
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Top investors take notice of your firm'}
              </Text>
            </VStack>
            {/* V Stack 3 */}
            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  { alignItems: 'center', marginBottom: 25, marginTop: 15 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['PartnershipHandshake'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_600SemiBold',
                      fontSize: { minWidth: Breakpoints.Desktop, value: 16 },
                      textAlign: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Deal with resulting interest'}
                {dimensions.width >= Breakpoints.Laptop ? '\n' : ' '}
                {'if/how you see fit'}
              </Text>
            </VStack>
          </HStack>
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['Networking'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  height: 350,
                  left: 20,
                  opacity: 0.1,
                  position: 'absolute',
                  width: 350,
                }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Tablet, value: 20 },
                { minWidth: Breakpoints.Mobile, value: 20 },
              ],
              marginRight: 20,
              maxWidth: { minWidth: Breakpoints.Laptop, value: '75%' },
            },
            dimensions.width
          )}
        >
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Desktop, value: 18 },
                  ],
                  marginRight: 20,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'As an independent research provider a core part of our job is to continually educate our subscribers on interesting companies, and the industries in which they operate, regardless of whether the company is currently for sale.'
            }
          </Text>
        </View>
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: [
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Tablet, value: 20 },
                { minWidth: Breakpoints.Mobile, value: 20 },
              ],
              marginRight: 20,
              marginTop: { minWidth: Breakpoints.Laptop, value: 25 },
            },
            dimensions.width
          )}
        >
          {/* H Stack 2 */}
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  marginBottom: 25,
                  marginTop: 25,
                  maxWidth: { minWidth: Breakpoints.Laptop, value: '75%' },
                }
              ),
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['AndreasTrappe6'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { borderRadius: 50, marginRight: 15 }
                ),
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_700Bold',
                  }
                ),
                dimensions.width
              )}
            >
              {'Andreas von Buchwald\nCEO & Founder'}
            </Text>
          </HStack>
        </View>
      </LinearGradient>

      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', minHeight: 100 },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginTop: 20 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['mainsightsfaviconlogo10243'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 50, marginBottom: 5, width: 50 }
              ),
              dimensions.width
            )}
          />
          {/* Text 5 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: palettes.App.Orange,
                  fontFamily: 'Poppins_900Black',
                  fontSize: 20,
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'M&A INSIGHTS'}
          </Text>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  fontFamily: 'Quicksand_700Bold',
                  marginBottom: 10,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Nordic Knowledge Partners ApS'}
          </Text>
          {/* Text 4 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_700Bold',
                    },
                  ],
                  marginBottom: 10,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'All rights reserved, 2024.'}
          </Text>
          {/* Text 2 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  alignSelf: 'center',
                  fontFamily: 'Quicksand_500Medium',
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Strandvejen 60\n2900 Hellerup\nDenmark'}
          </Text>
          {/* Text 3 */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  alignSelf: 'center',
                  fontFamily: 'Quicksand_500Medium',
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'info@mainsights.io'}
          </Text>
          <IconButton
            onPress={() => {
              const handler = async () => {
                try {
                  await WebBrowser.openBrowserAsync(
                    'https://www.linkedin.com/company/nordic-knowledge-partners/'
                  );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            size={32}
            icon={'AntDesign/linkedin-square'}
            style={StyleSheet.applyWidth(
              { marginBottom: 20 },
              dimensions.width
            )}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AboutScreen);
